<template>
	<div>
		<Heard />
		<div class="banner container-fluid"  style="margin: 0 auto;"></div>
		<div class="bannercont container-fluid">
			<img
				class="container-fluid"
				style="margin: 0 auto;"
				src="@/assets/image/ourcont.jpg"
				alt=""
			/>
		</div>

		<Footer />
	</div>
</template>

<script>
import Heard from "@/components/Heard.vue";
// import Newlist from "@/components/Newlist.vue";
import Footer from "@/components/Footer.vue";
// import { api_queryNewsTitleList } from "@/apis/apis.js";
export default {
	components: {
		Heard,
		// Newlist,
		Footer,
	},

	data() {
		return {
			totalCount: 1,
			currPage: 1,
			list: [],
			rightlist: [],
		};
	},

	async created() {
		// 两个请求
		// console.log(app);
		// console.log(query);
		// console.log(store);
		// await this.getlist();
		// await this.getrightlist();
	},
	methods: {},
};
</script>

<style
	lang="less"
	scoped
>
.container-fluid {
	min-width: 1200px;
}
/* 版心 */
.container {
	width: 1200px;
	margin: 0 auto;
}
.banner {
	max-width: 1920px;

	height: 400px;
	background: url(~@/assets/image/banner3.png) no-repeat center;
	background-size: 100% 100%;
}
.bannercont {
	/* // width: 1920px;
	// height: 1920px;
	// background: url(~@/assets/image/ourcont.png) no-repeat center;
	// background-size: 100% 100%; */
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		width: 1920px;
		-height: 1920px;
	}
}
</style>
